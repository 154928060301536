jQuery(document).ready(function($) {
	/* Mobile Menu */
	mobileNavigationMenuSelector = ".mobile-navigation-menu"
	menu = new Mmenu(mobileNavigationMenuSelector, {
		extensions: [
			"position-back",
			"position-top",
		],
		navbar: {
			add: true,
			title: ""
		},
		slidingSubmenus: false,
		screenReader: {
			aria: true,
			text: true,
		}
	}, {
		fixedElements: {
			elemInsertMethod: "prependTo"
		}
	});

	api = menu.API;
	$(".mobile-menu-button").click(function() {
		if ($(mobileNavigationMenuSelector).hasClass("mm-menu_opened")) {
			api.close();
		}
		else {
			api.open();
		}

		calculateMobileMenuMargin();
	});

	/* Accessible menu */
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .nav-link").click(function(event) {
			if (!$(this).parents(".hasChildren").hasClass("open")) {
				$(".menu-li").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".menu-li").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();

	$(".accesible-navigation-menu .nav-link").each(function() {
		if ($(this).hasClass("no-link")) {
			$(this).removeAttr("href");
		}
	});

	// Sticky header
	addStickyHeader();

	$(window).scroll(function() {
		addStickyHeader();
	});

	function addStickyHeader() {
		if ($(window).scrollTop() > 1) {
			$("body").addClass('sticky');
		}
		else {
			$("body").removeClass('sticky');
		}
	}

	/* Mobile menu marign calculation */
	$(window).on('load resize', function() {
		calculateMobileMenuMargin();
		calculatePageMargin();
	});

	function calculateMobileMenuMargin() {
		$(".mobile-navigation-menu").css("margin-top", $(".div-block-21").outerHeight());
	}

	// Toggle account login
	$( ".account-login-btn" ).click(function() {
		$( ".login-popup" ).toggleClass("active");
	});

	// Toggle search
	$( ".search-button" ).click(function() {
		$( ".mobile-search" ).toggleClass("active");

		setTimeout(function() {
			calculatePageMargin();
		}, 200);
	});

	function calculatePageMargin() {
		$(".slider").css("padding-top", $(".header-container").height());
		$(".section-divider").css("padding-top", $(".header-container").height());
	}

	/* webflow */
	/* eslint-disable */
	Webflow.require('ix2').init(
	{"events":{},"actionLists":{},"site":{"mediaQueries":[{"key":"main","min":992,"max":10000},{"key":"medium","min":768,"max":991},{"key":"small","min":480,"max":767},{"key":"tiny","min":0,"max":479}]}}
	);
	/* eslint-enable */
});
